import React from "react";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Button,
  Avatar,
} from "@material-tailwind/react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Adminlogout, logout } from "../../../redux/actions/authAction";
import { UserAvatar } from "../assets/Avatar";
import ReactTimeAgo from "react-time-ago";
import { markNotificationAsRead } from "../../../services/endpoint";
import { FaBell } from "react-icons/fa";

export default function Header() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.auth.user);
  const { adminNotifications, userNotifications } = useSelector(
    (state) => state.notifications
  );

  const navigate = useNavigate();

  // function ShowNotify() {
  //     setNotifyDown(current => !current)
  // }
  const getUserType = (type) => {
    switch (type) {
      case "admin":
        return adminType();
      case "professional":
        return "Service Partner";
      case "vendor":
        return "Product Partner";
      case "private_client":
        return "Private Client";
      case "corporate_client":
        return "Corporate Client";
      default:
        return "";
    }
  };
  const adminType = () => {
    let levelAdmin = "Admin";

    if (admin?.level === 1) {
      levelAdmin = "Super Admin";
    } else if (admin?.level === 3) {
      levelAdmin = "Finance Admin";
    } else if (admin?.level === 2) {
      levelAdmin = "Article Admin";
    } else if (admin?.level === 4) {
      levelAdmin = "Product Admin";
    } else if (admin?.level === 5) {
      levelAdmin = "Project Admin";
    }

    return levelAdmin;
  };

  let count = 0;
  let content;
  const gotoNotification = async (id) => {
    const url =
      auth?.user?.userType === "admin"
        ? "/dashboard/notification"
        : "/dashboard/notify";
    navigate(url);
    await markNotificationAsRead(id);
  };
  if (auth?.user?.userType === "admin") {

    const notice = adminNotifications.filter(where => !where.isRead)
    count = notice.length
    content =
      notice.length > 0
        ? notice.slice(0, 6).map((notify) => {
            return (
              <p
                key={notify.id}
                className="my-5 flex"
                onClick={() => gotoNotification(notify.id)}
              >
                <div>
                  <img
                    src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1667908351/BOG/jot-logo_q4jjyl.png"
                    className="mx-3 mt-1 w-7 h-7 shadow p-1"
                    alt="Notify"
                  />
                </div>
                <div>
                  <p>{notify.message.substring(0, 30) + "..."}</p>
                  <p className="text-gray-500 text-xs">
                    <ReactTimeAgo
                      date={Date.parse(notify.createdAt)}
                      locale="en-US"
                    />
                  </p>
                </div>
              </p>
            );
          })
        : null;
  } else {
    const notice = userNotifications.filter(where => !where.isRead)
    count = notice.length;
    content =
      notice.length > 0
        ? notice.slice(0, 4).map((notify) => {
            return (
              <p
                key={notify.id}
                className="my-3 flex"
                onClick={() => gotoNotification(notify.id)}
              >
                <div>
                  <img
                    src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1667908351/BOG/jot-logo_q4jjyl.png"
                    className="mx-3 w-9"
                    alt="Notify"
                  />
                </div>
                <div>
                  <p>{notify.message.substring(0, 22)}</p>
                  <p className="text-gray-500 text-xs">
                    <ReactTimeAgo
                      date={Date.parse(notify.createdAt)}
                      locale="en-US"
                    />
                  </p>
                </div>
              </p>
            );
          })
        : null;
  }

  return (
    <div className="fixed w-full z-50 bg-gray-100">
      <div className="flex w-full bg-white justify-between lg:head-grid">
        <div className="bg-white py-3  text-center">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1669563824/BOG/logo_1_1_ubgtnr.png"
              alt="boglogo"
              className="w-24 xl:w-32 ml-3 md:ml-6"
            />
          </Link>
        </div>
        <div className="lg:shadow bg-white py-4 px-5 flex lg:justify-between justify-end  items-center">
          <div className="ml-10 hidden lg:flex items-center w-6/12">
            <p className="ml-5 fw-700 hidden lg:block">
              {auth?.user ? getUserType(auth?.user?.userType) : ""} Dashboard
            </p>
          </div>
          <div className="flex items-center justify-end w-full">
            <div>
              {auth?.user?.userType === "admin" ? null : (
                <Link to="switch">
                  <div>
                    <img
                      src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1671725813/BOG/switch_aflj0d.png"
                      alt="switch"
                      className="w-6 mx-auto"
                    />
                    <p className="text-center fs-300 fw-500 text-primary">
                      Switch
                    </p>
                  </div>
                </Link>
              )}
            </div>
            <div className="relative h-12 w-12">
            <div className="lg:absolute -top-2 left-0">
              <Menu placement="bottom-end">
                <MenuHandler>
                  <Button className="p-3 bg-transparent shadow-none hover:shadow-none">
                    <div className="bg-blue-100 px-2 rounded-sm py-2 relative">
                      <FaBell className="lg:text-xl text-lg text-primary" />
                      <p className="absolute -top-2 -right-4 border circle px-1 text-white text-xs bg-primary">
                        {count}
                      </p>
                    </div>
                  </Button>
                </MenuHandler>
                <MenuList className="p-0">
                  <MenuItem className="p-0 pb-4 w-64 lg:w-72">
                    <p className="mb-3 text-white bg-primary py-2 pl-3 text-lg fw-600">
                      Notifications
                    </p>
                    {content}
                    <Link
                      to={
                        auth?.user?.userType === "admin"
                          ? "notification"
                          : "notify"
                      }
                    >
                      <p className="text-center hover:text-orange-500">
                        View All
                      </p>
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
            </div>
            <div className="ml-5">
              <Menu placement="bottom-end">
                <MenuHandler>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    {auth?.user?.photo ? (
                      <Avatar src={auth.user.photo} />
                    ) : (
                      <UserAvatar />
                    )}
                  </Button>
                </MenuHandler>
                <MenuList>
                  {auth?.user?.userType === "admin" ? (
                    ""
                  ) : (
                    <MenuItem onClick={() => navigate("/dashboard/inbox")}>
                      Messages
                    </MenuItem>
                  )}

                 {!auth?.user?.userType === "admin" && <MenuItem onClick={() => navigate("/dashboard/meetings")}>
                    Meetings
                  </MenuItem>}
                  <MenuItem onClick={() => navigate("/dashboard/settings")}>
                    Profile Settings
                  </MenuItem>
                  {auth?.user?.userType === "admin" ? (
                    <MenuItem onClick={() => dispatch(Adminlogout())}>
                      Sign Out
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={() => dispatch(logout())}>
                      Sign Out
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
