import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaFileDownload,
} from "react-icons/fa";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
  usePagination,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useMemo } from "react";
import * as moment from "moment";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Loader } from "../../../layouts/Spinner";
import { formatAsNgnMoney } from "../../../../services/helper";
import dayjs from "dayjs";

// export table files

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns // eslint-disable-next-line
      .filter((c) => c.Header != "Action")
      .map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns // eslint-disable-next-line
      .filter((c) => c.Header != "Action")
      .map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns // eslint-disable-next-line
      .filter((c) => c.Header != "Action")
      .map((column) => column.exportValue);
    const doc = new jsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

export default function OrderTable({ status, loader, refund, markRefund }) {
  // let adminOrders = useSelector((state) => state.orders.adminOrders);
  let adminOrders = useSelector((state) => state.orders.adminOrders);

  if (status) {
    adminOrders = adminOrders.filter((where) => where.status === status);
  }
  if (refund) {
    adminOrders = adminOrders.filter((where) => where.refundStatus === refund);
  }
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const navigate = useNavigate();
  const gotoDetailsPage = (id) => {
    navigate(`/dashboard/orderadmindetail?productId=${id}`);
  };
  const formatStatus = (status) => {
    switch (status) {
      case "in_review":
        return (
          <p className="px-2 py-1 text-blue-700 bg-blue-100 w-24 rounded-md fw-600">
            Ongoing
          </p>
        );
      case "approved":
        return (
          <p className="px-2 py-1 text-green-700 bg-green-100 w-24 rounded-md fw-600">
            Confirmed
          </p>
        );
        case "shipped":
        return (
          <p className="px-2 py-1 text-purple-700 bg-purple-100 w-24 rounded-md fw-600">
            Shipped
          </p>
        );
      case "completed":
        return (
          <p className="px-2 py-1 text-green-700 bg-green-100 w-28 rounded-md fw-600">
            Completed
          </p>
        );
      case "cancelled":
        return (
          <p className="px-2 py-1 text-red-700 bg-red-100 w-28 rounded-md fw-600">
            Cancelled
          </p>
        );
      case "pending":
        return (
          <p className="px-2 py-1 text-blue-700 bg-blue-100 w-24 rounded-md fw-600">
            Pending
          </p>
        );
      case "draft":
        return "Draft";
      default:
        return status;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (row, index) => index + 1, //RDT provides index by default
      },
      {
        Header: "Order ID	",
        accessor: "orderSlug",
      },
      {
        Header: "Product Name",
        accessor: "order_items[0].product.name",
        Cell: (row) =>
          row.row.original.order_items.map((item) => (
            <p className="text-center bg-gray-50 fs-500 shadow-sm px-2 py-1 mb-1 rounded-full">
              {item.product.name}
            </p>
          )),
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: (props) => moment(props.value).format("MMMM Do YYYY , h:mm:ss a"),
        // Filter: SelectColumnFilter,
        // filter: 'includes',
      },
      {
        Header: "Price",
        accessor: "totalAmount",
        Cell: (props) => `NGN ${formatNumber(props.value)}`,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => formatStatus(props.value),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: (row) => (
          <Menu placement="left-start" className="w-16">
            <MenuHandler>
              <Button className="border-none bg-transparent shadow-none hover:shadow-none text-black">
                <p className="lg:text-xl">
                  <BsThreeDotsVertical />
                </p>
              </Button>
            </MenuHandler>
            <MenuList className="w-16 bg-gray-100 fw-600 text-black">
              <MenuItem onClick={() => gotoDetailsPage(row.value)}>
                View Details
              </MenuItem>
              {row.row.original.refundStatus === "request refund" ? (
                <MenuItem onClick={() => markRefund(row.value)}>
                  Make as Refunded
                </MenuItem>
              ) : (
                ""
              )}
            </MenuList>
          </Menu>
        ),
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const data = useMemo(() => adminOrders, [adminOrders]);

  return (
    <>
      <div className="overflow-hidden px-4 bg-white py-8 rounded-md">
        {loader ? (
          <Loader size />
        ) : (
          <Table columns={columns} data={data} className="" />
        )}
      </div>
    </>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex items-center h-9 overflow-hidden  lg:w-64  rounded-md">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="px-2 py-1 h-full border-2 border-gray-400 rounded-l-md w-10/12  outline-none"
      />
      <p className="w-2/12 h-full center-item rounded-r-md bg-primary ">
        <FontAwesomeIcon
          icon={faSearch}
          className="text-xl py-1 rounded-r-md bg-primary text-white text-center"
        />
      </p>
    </div>
  );
}

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    exportData,
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useExportData
  );

  const exportFilteredData = (name, fileType) => {
    // Get the current filtered rows
    const filteredRow = rows.map((row) => row.original);
    const headNames = [
      "S/N",
      "Order Id",
      "Date Purchased",
      "Status",
      "Order Total",
      "Order Items"
    ];
    const bodyData = filteredRow.map((item, index) => [
      index + 1,
      item.orderSlug,
      dayjs(item.createdAt).format("DD-MMM -YYYY"),
      item.status,
      formatAsNgnMoney(item.totalAmount),
      `${item.order_items.map((item) => item.product.name).join('; ')}`,
    ]);

    if (fileType === "csv") {
      // CSV example
      const csvString = Papa.unparse({ fields: headNames, data: bodyData });
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.csv`; // Set the filename
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return;
    }
    if (fileType === "pdf") {
      // const downloadAsPDF = () => {
      const doc = new jsPDF();
      doc.autoTable({
        head: [headNames],
        body: bodyData,
      });

      return doc.save(`${name}.pdf`);
    }
    // exportData(name, true, exportData);
  };

  return (
    <>
      <div className="flex items-center mb-5 justify-between">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="flex">
          <Menu>
            <MenuHandler>
              <Button className="p-0 m-0 bg-transparent shadow-none text-blue-800 hover:shadow-none flex items-center">
                Export <FaFileDownload className="text-2xl" />
              </Button>
            </MenuHandler>
            <MenuList>
              <MenuItem
                onClick={() => {
                  exportFilteredData("order-data", "csv");
                }}
              >
                Export as CSV
              </MenuItem>
              <MenuItem
                onClick={() => {
                  exportFilteredData("order-data", "pdf");
                }}
              >
                Export as PDF
              </MenuItem>
            </MenuList>
          </Menu>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter ? (
                <div
                  className="fs-500 px-3 py-2 rounded-md bg-light"
                  key={column.id}
                >
                  <label for={column.id} className="fw-600 ">
                    {column.render("Header")}:{" "}
                  </label>
                  {column.render("Filter")}
                </div>
              ) : null
            )
          )}
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="items-center w-full bg-transparent border-collapse"
              >
                <thead className="thead-light bg-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          scope="col"
                          className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap text-left"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="bg-white" {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className="border-b border-gray-200 align-middle fs-500 whitespace-nowrap px-2 py-4 text-left"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="pagination mt-8 flex justify-between items-center">
        <div>
          <span>
            Page{" "}
            <strong>
              {state.pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <select
            value={state.pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            className="border border-gray-400 px-2 p-1 text-xl"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FaAngleDoubleLeft />
          </button>{" "}
          <button
            className="border border-gray-400 px-2 p-1 text-xl"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FaAngleLeft />
          </button>{" "}
          <button
            className="border border-gray-400 px-2 p-1 text-xl"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FaAngleRight />
          </button>{" "}
          <button
            className="border border-gray-400 px-2 p-1 text-xl"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </>
  );
};

// dropdown filter for table

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      name={id}
      id={id}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      className="bg-light outline-none"
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
