import React, { useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Spinner from "../../../../layouts/Spinner";
import { FaTimes } from "react-icons/fa";
import usePostHook from "../../../../../hooks/usePost";

const RescheduleMeeting = ({ item, CloseModal }) => {
  const [loading, setLoading] = useState(false);
  const { handlePost } = usePostHook();
  const onSuccess = () => {
    // refetch();
    setLoading(true);
    toast.success("check");
  };

  const handleReschedule = async () => {
    setLoading(true);
    const payload = {
      ...formik.values,
    };
    handlePost(
      `api/meeting/update/${item.id}`,
      payload,
      `application/json`,
      onSuccess
    );
  };

  const formik = useFormik({
    initialValues: {
      description: item.description || "",
      date: item.date.split('T')[0] || "",
      time: item.time || "",
    },
    onSubmit: handleReschedule,
  });
  const { date, description, time } = formik.values;

  const today = new Date().toISOString().split("T")[0];

  return (
    <div
      className="fixed font-primary left-0 top-0 w-full h-screen bg-op center-item z-40"
      onClick={CloseModal}
    >
      <div
        className="bg-white lg:w-5/12 rounded-md  overscroll-none  w-11/12 pt-5 shadow fw-500 scale-ani"
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={formik.handleSubmit} className="p-5">
          <div className="flex justify-between">
            <p className="lg:text-lg fw-600">Reschedule Meeting</p>
            <FaTimes onClick={CloseModal} />
          </div>
          <div className="mt-5 lg:mt-8">
            <label className="block">Project ID</label>
            <div className="border rounded-lg p-2 border-gray-400">
                <p>{item.projectSlug}</p>
            </div>
          </div>
          <div className="mt-5">
            <label className="block">Preferred Meeting Date/Time</label>
            <div className="flex">
              <input
                type="date"
                name="date"
                id="date"
                value={date}
                min={today}
                onChange={formik.handleChange}
                className="w-full lg:w-6/12 border border-gray-400 rounded mt-2 py-2 px-2"
                required
              />
              <input
                type="time"
                min="00:00"
                max="23:59"
                name="time"
                id="time"
                value={time}
                onChange={formik.handleChange}
                className="w-full ml-6 lg:w-6/12 border border-gray-400 rounded mt-2 py-2 px-2"
                required
              />
            </div>
          </div>
          <div className="mt-5">
            <label className="block">Description</label>
            <textarea
              name="description"
              id="description"
              value={description}
              onChange={formik.handleChange}
              className="w-full lg:w-full h-24 border border-gray-400 rounded mt-2 p-2"
              required
            ></textarea>
          </div>
          <div className="mt-8 flex lg:w-full justify-between">
            <button
              className="btn bg-red-500 lg:px-7 text-white"
              onClick={CloseModal}
            >
              Cancel
            </button>
            {loading ? (
              <Spinner />
            ) : (
              <button className="btn-primary lg:px-7">
                Reschedule Meeting
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RescheduleMeeting;
