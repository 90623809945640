import { Breadcrumbs } from "@material-tailwind/react";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MessageModal } from "./MessageModal";
import { useSelector } from "react-redux";
import useFetchHook from "../../../../../hooks/useFetchHook";
import { Loader } from "../../../../layouts/Spinner";
import { cutText, getUserTypeRevserse } from "../../../../../services/helper";
import moment from "moment";
import { BiSupport } from "react-icons/bi";
import ContactDetails from "./contact-details";

const AnnounceUser = () => {
  const [readMessage, setReadMessage] = useState(false);
  const [message, setMessage] = useState();
  const user = useSelector((state) => state.auth.user);
  const { data: announcements, loading } = useFetchHook(
    `announcements?userType=${getUserTypeRevserse(user?.userType)}`
  );
  const [showContact, setShowContact] = useState(false);

  const announces = announcements?.filter((where) => where.expiredAt !== null);

  const openMessage = (val) => {
    setMessage(val);
    setReadMessage(true);
  };
  const closeModal = () => {
    setReadMessage(false);
  };

  if (loading) {
    return (
      <center>
        <Loader />
      </center>
    );
  }

  return (
    <div>
      <div className="min-h-screen fs-500 relative">
        {/* header */}
        <div className="w-full py-8 bg-white px-4 lg:flex justify-between items-center">
          <div>
            <p className="text-2xl fw-600">Announcements</p>
            <p className="fs-400 text-gray-600 mt-2">
              Receive announcements and updates from the BOG team.
            </p>
            <Breadcrumbs className="bg-white pl-0 mt-4">
              <Link to="/" className="opacity-60">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </Link>
              <Link to="/dashboard" className="opacity-60">
                <span>Dashboard</span>
              </Link>
              <Link to="" className="">
                <span>Inbox</span>
              </Link>
            </Breadcrumbs>
          </div>
          <div>
            {/* <button
              onClick={() => setShowContact(true)}
              className="flex items-center gap-x-2 bg-primary text-white fw-600 px-6 py-3 rounded-lg text-lg"
            >
              <BiSupport className="text-xl" /> Contact Support
            </button> */}
          </div>
        </div>
        {/* content */}
        <div className="lg:p-5 px-3 py-5 mt-6">
          <div className="p-6 bg-white rounded-lg">
            {announces &&
              announces.map((item) => (
                <div
                  key={item.id}
                  className="border-b p-2 group relative hover:shadow-md duration-300 cursor-pointer mt-2 border-gray-300 w-full lg:flex items-center"
                  onClick={() => openMessage(item)}
                >
                  <div className="lg:w-3/12">
                    <p className="fw-500"> {item.title} </p>
                  </div>
                  <div className="lg:w-7/12">
                    <p> {cutText(item.content, 80, "...")} </p>
                  </div>
                  <div className="lg:w-2/12">
                    <p className="fs-400">
                      {" "}
                      {moment(item.createdAt).format(
                        "MMMM Do YYYY , h:mm a"
                      )}{" "}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {readMessage && (
        <div
          className="fixed font-primary left-0 top-0 w-full h-screen bg-op center-item z-40"
          onClick={closeModal}
        >
          <div
            className="bg-white lg:w-7/12 rounded-md  overscroll-none  w-11/12 p-8 shadow max-h-70 scale-ani overflow-y-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <MessageModal closeModal={closeModal} message={message} />
          </div>
        </div>
      )}
      {showContact && (
        <div
          className="fixed font-primary left-0 top-0 w-full h-screen bg-op center-item z-40"
          onClick={() => setShowContact(false)}
        >
          <div
            className="bg-white lg:w-[600px] rounded-md  overscroll-none  w-11/12 p-8 shadow max-h-70 scale-ani overflow-y-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <ContactDetails />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnounceUser;
