import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { usePaystackPayment } from "react-paystack";
import Swal from "sweetalert2";
import { formatNumber } from "../../../../../services/helper";
import Axios from "../../../../../config/config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../layouts/Spinner";
import KycAlert from "../../../assets/KycAlert";

const SubscriptionItem = ({ item, user }) => {
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const navigate = useNavigate();
  const config = {
    reference: "TR-" + new Date().getTime().toString(),
    email: `${user.email}`,
    amount: item.amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_API_KEY,
  };

  const subscribeToPlan = async (reference) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("auth_token"),
        },
      };
      const data = {
        userId: user.id,
        userType: user.userType,
        planId: item.id,
        reference,
      };
      // eslint-disable-next-line
      const response = await Axios.post(
        "/subscription/subscribe",
        data,
        config
      );
      setSubscribed(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    try {
      setLoading(true);
      // Implementation for whatever you want to do with reference and after success call.
      subscribeToPlan(reference.reference);

      Swal.fire({
        title: "Success",
        imageUrl:
          "https://res.cloudinary.com/greenmouse-tech/image/upload/v1686055425/BOG/success_afvfig.jpg",
        imageWidth: "75px",
        text: "Congratulations, your subscription is successful",
        buttonsStyling: "false",
        confirmButtonText: "Continue",
        confirmButtonColor: "#3F79AD",
      }).then(function () {
        navigate("/dashboard");
        window.location.reload();
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error",
        icon: "error",
        text: "Unable to Finish subscription. Please contact administrator",
      });
    }
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  const handleInitalize = async () => {
    if(item.amount > 0){
        initializePayment(onSuccess, onClose);
    }else{
        await subscribeToPlan(item.id)
        Swal.fire({
            title: "Success",
            imageUrl:
              "https://res.cloudinary.com/greenmouse-tech/image/upload/v1686055425/BOG/success_afvfig.jpg",
            imageWidth: "75px",
            text: "Congratulations, your subscription is successful",
            buttonsStyling: "false",
            confirmButtonText: "Continue",
            confirmButtonColor: "#3F79AD",
          }).then(function () {
            navigate("/dashboard");
            window.location.reload();
          });
    }
  }
  if (loading) {
    return (
      <center>
        <Spinner />
      </center>
    );
  }
  return (
    <>
      {subscribed && <KycAlert reload={true} />}
      <div className="w-11/12 mb-7 lg:mb-0 mx-auto ">
        <div className="hover:scale-110 hover:bg-black hover:text-white transition duration-300 lg:p-10 p-6 rounded-md bg-white shades">
          <div className="flex pb-5">
            <img
              src="https://res.cloudinary.com/greenmouse-tech/image/upload/v1670591820/BOG/basic-removebg-preview_aeltgx.png"
              alt="basic"
              className="w-16 bg-primary rounded-lg"
            />
            <div className="pl-3  fs-400 fw-600">
              <p>{item.name}</p>
              <p className="">{item.duration} weeks</p>
              <p>&#8358;{formatNumber(item.amount)}</p>
            </div>
          </div>
          <div className="border-t py-4">
            {item.benefits.map((ben) => (
              <div className="flex items-center" key={ben.id}>
                <GiCheckMark />
                <p className="pl-1">{ben.benefit}</p>
              </div>
            ))}
          </div>
          <div className="text-center">
            <button
              className="flex items-center w-full btn-primary"
              onClick={handleInitalize}
            >
              Choose Plan{" "}
              <span className="pl-2">
                <BsArrowRightCircleFill />
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionItem;
